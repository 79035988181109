<!-- #Developer by Halil Kılıçarslan -->

<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    v-model="initialValue"
    :items="branches"
    :loading="loading"
    item-value="id"
    item-text="name"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
    color="blue-grey lighten-2"
    dense
    @change="fetchBranchEmployees"
    @click:clear="onClearClicked"
  >
    <template v-slot:prepend-item v-if="$attrs.hasOwnProperty('multiple') && branches.length">
      <v-list-item ripple @click="selectAll">
        <v-list-item-action>
          <v-icon>{{ _icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("buttons.select_all") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
    <template v-if="$attrs.hasOwnProperty('multiple')" v-slot:selection="{ item, index }">
      <div v-if="index === 0">
        {{ index === 0 ? `${initialValue.length} ${$t("global.selected", [$t("global.branch")])}` : "" }}
      </div>
    </template>
    <template v-else v-slot:selection="{ item, index }">
      {{ item.name }}
    </template>

    <template slot="item" slot-scope="data">
      <template>
        <v-list-item-avatar size="30" :color="data.item.color">
          <span class="white--text font-weight-bold">{{ data.item.name.substring(0, 2).toUpperCase() }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name" />
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
  import * as GQL from "./gql";

  export default {
    inheritAttrs: false,
    name: "BranchPicker",
    data: vm => ({
      loading: false,
      selected: null,
      branches: [],
      employeeList: [],
      search: null,
      initialValue: []
    }),
    props: {
      value: [String, Object, Array],
      defaultBranch: {
        type: Boolean,
        required: false,
        default: true
      },
      employees: Array
    },
    watch: {
      value: {
        handler(v) {
          if (v) {
            this.initialValue = v;
          } else {
            if (this.$attrs.hasOwnProperty("multiple")) {
              this.initialValue = [];
            } else {
              this.initialValue = null;
            }
          }
        },
        immediate: true
      }
    },
    computed: {
      isAllSelectedEmployees() {
        return this.initialValue.length === this.branches.length;
      },
      isLikesSelectedEmployees() {
        return this.initialValue.length > 0 && !this.isAllSelectedEmployees;
      },
      _icon() {
        if (this.isAllSelectedEmployees) return "mdi-close-box";
        if (this.isLikesSelectedEmployees) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      }
    },
    methods: {
      onClearClicked() {
        this.$emit("clear");
        this.$emit("update:employees", []);
      },
      async setDefaultBranchDataWithEmployeeEvents() {
        this.loading = false;
        await this.$apollo
          .query({
            query: GQL.DEFAULT_BRANCH,
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data: { root }, errors }) => {
            if (!errors) this.selected = root;
          })
          .catch(e => {
            console.log("e", e);
          })
          .finally(() => {
            this.fetchBranchEmployees();
          });
      },
      async fetchBranchEmployees() {
        if (!this.selected) return false;
        this.employeeList = [];
        this.$emit("loading:employees", true);
        await this.$apollo
          .query({
            query: GQL.EMPLOYEES_BRANCH,
            variables: {
              criteria: {
                branches: this.selected.id
              }
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data: { filterEmployees }, errors }) => {
            if (!errors) {
              this.employeeList = filterEmployees;
              this.$emit("branch:employees", this.employeeList);
              this.$emit("update:employees", this.employeeList);
              this.$emit("loading:employees", false);
            }
          })
          .catch(e => {
            console.log("e", e);
          })
          .then(() => {
            this.loading = false;
          });
      },

      async fetchBranch() {
        this.branches = [];
        await this.$apollo
          .query({
            query: GQL.BRANCH,
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data: { branches }, errors }) => {
            if (!errors) {
              this.branches = branches || [];
              if (this.defaultBranch) {
                this.setDefaultBranchDataWithEmployeeEvents();
              }
            }
          })
          .catch(e => {
            console.log("e", e);
          });
      },

      async searchItems(text) {
        if (!text) return;

        this.loading = true;
        await this.$apollo
          .query({
            query: GQL.FILTER_BRANCH,
            variables: {
              criteria: {
                name: text
              }
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data: { filterBranches }, errors }) => {
            if (!errors) this.branches = filterBranches;
          })
          .catch(exception => {
            console.error("e", exception);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      customFilter(item, queryText, itemText) {
        const name = item.name.toLocaleLowerCase(this.$store.state.language);
        const searchText = queryText.toLocaleLowerCase(this.$store.state.language);
        return name.indexOf(searchText) > -1;
      },
      selectAll() {
        if (this.branches.length) {
          this.$nextTick(() => {            
            if (this.isAllSelectedEmployees) {              
              this.innerValue = [];
            } else {
              console.log(
                "4",
                this.branches.map(item => item.id)
              );
              this.innerValue = this.branches.map(item => item.id);
            }
            this.$emit("input", this.innerValue);
          });
        }
      }
    },

    created() {
      this.fetchBranch();
    }
  };
</script>

<style scoped></style>
